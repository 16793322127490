import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private nav :NavController) {
    this.initializeApp()
  }


  async initializeApp() {


    
     
        this.nav.navigateRoot("home")
     

  
  }

}
