import { Component } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { NavController } from '@ionic/angular';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private nav :NavController,private glb : GlobalService) {
    this.initializeApp()

    Network.addListener("networkStatusChange", (state: ConnectionStatus) => {
      console.clear();
      console.log("Network Changed", state);
      console.log(location);    
      this.glb.connected = state.connected;     
    });
  }


  

  async initializeApp() {


    
     
        this.nav.navigateRoot("home")
     

  
  }

}
