import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  oLoading: HTMLIonLoadingElement
  connected : boolean = true
  constructor(private loadingCtrl: LoadingController,
    private toasCtrl: ToastController,) { }

  async showLoading(m: string) {

    let options: LoadingOptions = {
      message: '<ion-img src="/assets/icon/loading.gif" alt="loading..."></ion-img>',
      cssClass: 'custom-loading',
      translucent: false,
      showBackdrop: false,
      spinner: null,
      keyboardClose: true,
      duration: 30000
    };

    let opt2 = {
      cssClass: 'loading-controller',
      message: "",
      duration: 30000
    }


    this.oLoading = await this.loadingCtrl.create(options);
    await this.oLoading.present();
  }

  async toast(header, message,
    position: 'top' | 'middle' | 'bottom',
    color: 'danger' | 'warning' | 'success',
    duration: number = 2000,
    button: boolean = false
  ) {



    let config = {
      header: header,
      message: message,
      position: position,
      color: color,
      animated: true,
      cssClass: "toast"
    }


    if (button) {
      config["buttons"] = [
        {
          text: 'Evet',
          role: 'cancel',
          handler: () => {
            
          }

        },
        {
          text: 'Hayır',
          role: 'cancel',
          handler: () => {
          
          }

        }
      ]
    } else {
      config["duration"] = duration
    }

    const toast = await this.toasCtrl.create(config);
    toast.present();
  }



  closeLoading() {
    if (this.oLoading) { this.oLoading.dismiss(); this.oLoading = undefined; }
    setTimeout(() => {
      if (this.oLoading) { this.oLoading.dismiss(); this.oLoading = undefined; }
    }, 500);
  }


}
